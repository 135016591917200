import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            isLoggedIn: false, // 默认未登录
        };
    },
    mutations: {
        SET_LOGGED_IN(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
        login(state) {
            state.isLoggedIn = true;
        },
        logout(state) {
            state.isLoggedIn = false;
        },
    },
    actions: {
        login({ commit }) {
            // 实际应用中可能是异步操作，例如发送登录请求
            commit('login');
        },
        logout({ commit }) {
            // 实际应用中可能是异步操作，例如发送注销请求
            commit('logout');
        },
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
    },
});

export default store;