<template>
  <h1>WebSocket Chat</h1>
  <div class="container my-5">
    <div id="board" class="row"></div>
  </div>
  <div id="timeDisplay"></div>
  <div style="display:flex">
    <input type="text" id="nameInput" placeholder="Set your name..." />
    <button id="setName">Set Name</button>
  </div>
  <div id="messages"></div>
  <div style="display:flex">
    <input type="text" id="messageInput" placeholder="Type your message here..." />
    <button id="sendMessage">Send</button>
  </div>

  <button id="Match">Match</button>
  <button id="Close">Close Match</button>

  <div id="userList" class="user-list"></div>
</template>

<script>







export default {

  name: "DemoView",


  data() {
    return {
      html: "<h1>Hello World</h1>",
      ws: null,
      Username: null,

      gameInfo: null,
      timerId: null,
      board: null,
      canvas: null,
      ctx: null,
      piece: null,

    };
  },
  methods: {},
  mounted() {
    let userid;
    if (this.$route.query.user) {
       userid = this.$route.query.user;
    }else{
       userid = Math.round(Math.random() * 1000);
    }

    const socketUrl = "wss://hsq020828.cpolar.top/" + userid;
    const ws = new WebSocket(socketUrl);
    let Username='';
    let timerId;
    let gameInfo;
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.id = 'ls'; // Set the ID to 'ls'
    canvas.width = 450; // Set the width
    canvas.height = 450; // Set the height


    // Get the 2D drawing context
    const ctx = canvas.getContext('2d');
    const gridSize = 15;  // 15x15 grid
    const cellSize = canvas.width / gridSize; // Calculate cell size
    ctx.strokeStyle = '#000'; // Set line color
    ctx.lineWidth = 1; // Set line width
    // Append the canvas to the body
    let board = Array(gridSize).fill(null).map(() => Array(gridSize).fill(null)); // To track the board state
    let isBlackTurn = true; // Track the current player's turn
    const timeDisplay = document.getElementById('timeDisplay');
    let seconds = 0;

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      ws.send('LIST');
    };

    ws.onmessage = async (event) => {
      const data = event.data.split('||');
      // Check if splitting resulted in the expected number of parts
      if (data.length < 2) {
        console.warn('Unexpected data format:', event.data);
        return;
      }
      if (data[0] === 'NAME_SET') {
        this.$message.success('Name set to:' + data[1]);
        Username = data[1]

      } else if (data[0] === 'ERROR') {
        this.$message.error(data[1]);

      } else if (data[0] === 'MESSAGE') {
        const [, senderId, message] = data;
        const messages = document.getElementById('messages');
        const msg = document.createElement('div');
        msg.textContent = `来自 ${senderId}的私聊消息: ${message}`;
        messages.appendChild(msg);
        messages.scrollTop = messages.scrollHeight;
      } else if (data[0] === 'LIST') {
        const userList = document.getElementById('userList');
        userList.innerHTML = ''; // Clear existing user list
        const users = data[1].split(',');
        users.forEach(user => {
          const [id, name] = user.split(':');
          const userElement = document.createElement('div');

          userElement.className = 'user';
          const userText = document.createElement('span');
          userText.textContent = id + ':' + name;
          userElement.appendChild(userText);

          // Create and append the video call button
          const videoCallButton = document.createElement('button');
          videoCallButton.textContent = 'Video Call';
          videoCallButton.className = 'video-call-button';
          videoCallButton.onclick = () => {
            initiateVideoCall(id); // Function to handle the video call logic
          };
          userElement.appendChild(videoCallButton);

          userElement.onclick = () => {
            const messageInput = document.getElementById('messageInput');
            messageInput.placeholder = `Message to ${name}`;
            messageInput.setAttribute('data-recipient', id);
          };
          userList.appendChild(userElement);
        });
      } else if (data[0] === 'ALL') {
        const [, senderId, message] = data;
        const messages = document.getElementById('messages');
        const msg = document.createElement('div');
        msg.textContent = `来自 ${senderId} 的全部消息: ${message}`;
        messages.appendChild(msg);
        messages.scrollTop = messages.scrollHeight;
      } else if (data[0] === 'GAME') {
        const [, msg, player, game] = data;


        switch (msg) {
          case '正在匹配': {


            // 每秒更新时间
            timerId = setInterval(updateTime, 1000);
            break;
          }

          case '匹配成功': {
            clearInterval(timerId);
            this.$message.success('匹配成功 ' + player);

            gameInfo = game;


            const timeDisplay = document.getElementById('timeDisplay');
            timeDisplay.textContent = '';


            // Call the drawGrid function to render the grid
            drawGrid();
            canvas.addEventListener('click', handleClick);
            break;
          }
          case 'gameData': {
            gameInfo = game;
            break;
          }
          case 'OVER': {
            const [, , winner] = data;
            this.$message.success(`游戏结束,获胜者为 ${winner}`);
            const element = document.getElementById('ls');
            if (element) {
              element.parentNode.removeChild(element);

            }
            break;

          }


        }

      } else if (data[0] === 'MOVE') {

        const [, move_data] = data;
        const move_data_json = JSON.parse(move_data);
        console.log(move_data_json)
        const x = move_data_json['x'];
        const y = move_data_json['y'];
        const color = move_data_json['color'];
        board[y][x] = color;
        drawPiece(x, y, color);


      } else if (data[0] === 'CALL') {
        const [, user] = data;
        const response = await this.$confirm(`${user} 请求视频通话`);

        if (response) {
          // 用户点击“确定”，进行跳转或其他操作
          ws.send(`CALL||ACCEPT||${user}||${Username}`);
          // 跳转到视频通话页面
          this.$router.push({name: 'chat', query: {user: userid}});

        } else {
          // 用户点击“取消”，可以添加拒绝的逻辑
          this.$message.error('视频通话请求已拒绝');
        }
      } else if (data[0] === 'ACCEPT_CALL') {
        const [, call] = data;
        this.$message.success(`${call} 接受了你的视频通话请求`);
        setTimeout(() => {
          this.$router.push({ name: 'chat', query: { user: userid, touser: call } });
        }, 900); // 延迟 2000 毫秒 (2 秒)
      }

    };
    function initiateVideoCall(id) {
      ws.send(`CALL||${id}||${Username}`);
      // Code to handle the video call logic
    }
    function updateTime() {
      const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
      const displaySeconds = (seconds % 60).toString().padStart(2, '0');
      timeDisplay.textContent = `正在匹配 ${minutes}:${displaySeconds}`;
      seconds++;
    }


    // Draw grid function
    function drawGrid() {
      document.body.appendChild(canvas);
      for (let i = 0; i <= gridSize; i++) {
        // Draw horizontal lines
        ctx.beginPath();
        ctx.moveTo(0, i * cellSize);
        ctx.lineTo(canvas.width, i * cellSize);
        ctx.stroke();

        // Draw vertical lines
        ctx.beginPath();
        ctx.moveTo(i * cellSize, 0);
        ctx.lineTo(i * cellSize, canvas.height);
        ctx.stroke();
      }
    }
    function drawPiece(x, y, color) {
      ctx.beginPath();
      const centerX = x * cellSize + cellSize / 2;
      const centerY = y * cellSize + cellSize / 2;
      ctx.arc(centerX, centerY, cellSize / 2 * 0.4, 0, Math.PI * 2);
      ctx.fillStyle = color;
      ctx.fill();
      ctx.stroke();
      if(color==='black'){
        isBlackTurn=false;
      }
    }

    function handleClick(event) {
      // 将单引号替换为双引号
      const correctedJsonString = gameInfo.replace(/'/g, '"');


      const data = JSON.parse(correctedJsonString);

      if(data['play1']===Username){
        const rect = canvas.getBoundingClientRect();
        const x = Math.floor((event.clientX - rect.left) / cellSize);
        const y = Math.floor((event.clientY - rect.top) / cellSize);


        // Check if the cell is already occupied
        if (board[y][x]) return;

        // Place the piece
        board[y][x] = isBlackTurn ? 'black' : 'white';
        drawPiece(x, y, isBlackTurn ? 'black' : 'white');
        const move_data={
          "type": "move",
          "x": x,
          "y": y,
          "color": isBlackTurn ? 'black' : 'white'
        }
        ws.send(`move||${JSON.stringify(move_data)}||${Username}||${correctedJsonString}`);
        ws.send(`update||${correctedJsonString}`);

        // Check for victory
        if (checkWin(x, y)) {

          // Optionally reset the board
          board = Array(gridSize).fill(null).map(() => Array(gridSize).fill(null));
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const element = document.getElementById('ls');
          if (element) {
            element.parentNode.removeChild(element);
            ws.send(`GAME_OVER||${correctedJsonString}||${Username}`);

          }
        } else {
          // Switch turn
          isBlackTurn = !isBlackTurn;
        }
      }else{
        alert('现在不是你的回合')
      }
    }



    function checkWin(x, y) {
      const color = board[y][x];
      return (
          checkDirection(x, y, 1, 0, color) || // Horizontal
          checkDirection(x, y, 0, 1, color) || // Vertical
          checkDirection(x, y, 1, 1, color) || // Diagonal \
          checkDirection(x, y, 1, -1, color)   // Diagonal /
      );
    }

    function checkDirection(x, y, dx, dy, color) {
      let count = 1;

      // Check one direction
      for (let i = 1; i < 5; i++) {
        const nx = x + i * dx;
        const ny = y + i * dy;
        if (nx >= 0 && ny >= 0 && nx < gridSize && ny < gridSize && board[ny][nx] === color) {
          count++;
        } else {
          break;
        }
      }

      // Check the opposite direction
      for (let i = 1; i < 5; i++) {
        const nx = x - i * dx;
        const ny = y - i * dy;
        if (nx >= 0 && ny >= 0 && nx < gridSize && ny < gridSize && board[ny][nx] === color) {
          count++;
        } else {
          break;
        }
      }

      return count >= 5;
    }




    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    document.getElementById('sendMessage').addEventListener('click', () => {
      const input = document.getElementById('messageInput');
      const message = input.value;
      const recipientId = input.getAttribute('data-recipient');
      if (message && recipientId) {
        ws.send(`MESSAGE||${recipientId}||${message}`);
        input.value = '';
        input.setAttribute('data-recipient', ''); // Clear recipient
      }else{
        ws.send(`ALL_MESSAGE||${Username}||${message}`);
      }
    });

    document.getElementById('setName').addEventListener('click', () => {
      const nameInput = document.getElementById('nameInput');
      const name = nameInput.value;
      if (name) {
        ws.send(`SET_NAME||${name}`);
        nameInput.value = '';
        ws.send('LIST'); // Request to update user list after setting the name
      }
    });
    document.getElementById('Match').addEventListener('click', () => {
      if(Username){

        ws.send(`GAME||Match||${Username}`);
      }
      else{
        this.$message.error('请先设置用户名')

      }

    });
    window.addEventListener('beforeunload', handleUnload);
    function handleUnload() {

      ws.send(`CLOSE||${userid}`);
    }
    document.getElementById('Close').addEventListener('click', () => {
// 将单引号替换为双引号
      const correctedJsonString = gameInfo.replace(/'/g, '"');


      const data = JSON.parse(correctedJsonString);

      if(data['play1']===Username){
        ws.send(`update||${gameInfo}`);
      }
      else{
        this.$message.error('现在不是你的回合')
      }



    });
  },
    beforeUnmount() {


    const element = document.getElementById('ls');
    if (element) {
      element.parentNode.removeChild(element);
    }
   }
}
</script>

<style scoped>
#messages {
  border: 1px solid #ddd;
  height: 300px;
  overflow-y: scroll;
}
#messageInput {
  width: 80%;
}
#sendMessage {
  width: 20%;
}
.user-list {
  margin-top: 10px;
}
.user {
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
