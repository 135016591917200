<script >
import request from '../utils/request'



export default {
  name: 'LoginView',
  props: ['show'],
  data() {
    return {
      from:{
        username: '',
        password: '',
      },
      emits: ['close-dialog'],

      error: ''
    }
  },

  methods: {

    Login() {
      request.post('/login',this.from).then(response => {
        if (response.code === '1') {
          this.$message.success('Login success')
          const user = JSON.stringify(this.from);
          localStorage.setItem('login_user', user);
          this.$emit('close-dialog'); // 发送事件给父组件
          this.$router.push('/')





        }
      else{
        this.$message.error(response.message)
        }})
      //跳转到首页,并将username传递给首页
      // router.push({ path: '/chat', query: { username: this.username } })


    }
  }
}
</script>

<template>
  <div class="login-container">
    <div class="login-form" >
      <h2>Login</h2>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" id="username" v-model="from.username" />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="from.password" />
      </div>
      <div class="form-group">
        <button type="button" class="btn btn-primary" @click="Login()">Login</button>
      </div>
      <div class="form-group">
        <p>{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}
.login-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}
.form-group {
  margin-bottom: 10px;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.form-group button {
  background-color: #42b983;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.form-group p {
  color: red;
  margin-top: 10px;
}
</style>