import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import axios from 'axios'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import store from "./utils/store";
import router from "@/router";



const app = createApp(App)

app.config.globalProperties.$axios = axios; // 将 Axios 添加到全局属性中
app.config.globalProperties.$baseURL=process.env.VUE_APP_BASEUA
app.config.globalProperties.$store=store
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router) // 使用自定义的路由插件
app.use(store) // 使用 Vuex
app.use(ElementPlus) // 使用 Element Plus 插件


app.mount('#app')

