import { createRouter, createWebHistory } from 'vue-router'

import Chat from "@/views/Chat.vue";
import Demo from "@/views/demo.vue";
import Login from "@/views/Login.vue";




const routes = [

  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'chat',
    component: Chat,
  },

  {
    path: '/demo',
    name: 'demo',
    component: Demo,

  },
  {
    path: '/file',
    name: 'file',
    component: File,

  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router