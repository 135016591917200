<template>
  <div>
    <h1>Chat</h1>
    <el-input v-model="socketUrl" placeholder="Enter socket url"></el-input>

    你的id:{{id}}

    在线用户:{{name_list}}
    <el-input v-model="userid" placeholder="Enter your user id"></el-input>
    <el-button type="primary" @click="cn(this.userid)">Connect</el-button>
    <el-button type="primary" @click="openVideo">Open Video</el-button>
    <el-button type="danger" @click="hangUp">Hang Up</el-button>
    <div class="chat-container">
      <div class="video-container">
        <video id="iv" autoplay></video>
        <div class="overlay-container">
          <video id="iv2" autoplay></video>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ChatView",
  data() {

    return {
      socket: null,
      pc: null,
      localStream: null,
      userid: "",
      socketUrl: "",
      id:'',
      name_list:[]

    };
  },

  methods: {

    InitSocket() {
      let userid=this.$route.query.user;


      this.socketUrl = "wss://hsq020828.cpolar.top/"+ userid;
      let ws = new WebSocket(this.socketUrl);
      this.socket = ws;

      this.socket.onclose = (e) => console.log("Connection closed:", e.code);
      this.socket.onopen = () => console.log("Connection established");
      this.socket.onerror = (e) => console.error("Socket error:", e);
      this.socket.onmessage = async (event) => {
        const obj = JSON.parse(event.data);
        const {type, userId} = obj;
        if (userId && userId !== userid.toString()) {

          // 如果目标用户ID不匹配，忽略该消息
          return;
        }
        try {
          if (type === "offer") {
            if (this.pc) {
              console.error("PeerConnection already exists!");
              return;
            }
            this.pc = this.InitPeerConnection(userid);
            const desc = new RTCSessionDescription(obj);
            await this.pc.setRemoteDescription(desc);
            const answer = await this.pc.createAnswer();
            await this.pc.setLocalDescription(answer);
            this.socket.send(JSON.stringify(answer));
          } else if (type === "answer") {
            if (!this.pc) {
              console.error("PeerConnection does not exist!");
              return;
            }
            const desc = new RTCSessionDescription(obj);
            await this.pc.setRemoteDescription(desc);
          } else if (type === "candidate") {
            const candidate = new RTCIceCandidate({
              sdpMLineIndex: obj.sdpMLineIndex,
              sdpMid: obj.sdpMid,
              candidate: obj.candidate
            });
            await this.pc.addIceCandidate(candidate);
          } else if (type === "hangup") {
            // Handle hangup
            this.hangUp();
            console.log("Received hangup message, call ended.");
          } else if (type === "name_list") {
            this.id = obj.your_id;
            this.name_list = obj.data;
          }
        } catch (error) {
          console.error("Error handling message:", error);
        }
      }



    },
    InitPeerConnection(targetUserId) {
      let peerconnection = null;
      try {
        // 使用 RTCPeerConnection 构造函数
        peerconnection = window.webkitRTCPeerConnection
            ? new window.webkitRTCPeerConnection()
            : new RTCPeerConnection();
      } catch (e) {
        console.error("Connection failed:", e.message);
        return null; // 如果创建连接失败，返回 null
      }

      // 处理 ICE 候选
      peerconnection.onicecandidate = (evt) => {
        if (evt.candidate) {
          const candidate = {
            type: "candidate",
            userId: targetUserId,
            sdpMid: evt.candidate.sdpMid,
            sdpMLineIndex: evt.candidate.sdpMLineIndex,
            candidate: evt.candidate.candidate
          };
          this.socket.send(JSON.stringify(candidate));
        }
      };

      // 添加本地流
      if (this.localStream) {
        this.localStream.getTracks().forEach(track => {
          peerconnection.addTrack(track, this.localStream);
        });
      }

      // 处理远程流
      peerconnection.ontrack = (event) => {
        const remoteStream = event.streams[0];
        document.getElementById("iv2").srcObject = remoteStream;
        // 可能不需要再调用 play()，因为浏览器通常会自动处理
      };

      return peerconnection;
    },
    cn(targetUserId) {
      this.pc = this.InitPeerConnection(targetUserId);
      this.pc.createOffer()
          .then((desc) => {
            // 创建一个包含 offer 和 userId 的新对象
            const offerMessage = {
              type: 'offer',
              sdp: desc.sdp,
              userId: targetUserId
            };

            return this.pc.setLocalDescription(desc).then(() => {
              this.socket.send(JSON.stringify(offerMessage));
            });
          })
          .catch((err) => console.error("Create offer failed:", err));
    },
    openVideo() {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
          .then(stream => {
            this.localStream = stream;
            const videoElement = document.getElementById("iv");
            videoElement.srcObject = stream;
            videoElement.play();
          })
          .catch(error => {
            console.error("Error accessing media devices.", error);
          });
    },
    hangUp() {

        if (this.pc) {
          // 发送挂断消息到对方
          const hangUpMessage = JSON.stringify({type: "hangup", userId: this.$route.query.touser});
          this.socket.send(hangUpMessage);

          // 关闭 PeerConnection 和 WebSocket
          this.pc.close();
          this.pc = null;

          if (this.localStream) {
            this.localStream.getTracks().forEach(track => track.stop());
            this.localStream = null;
          }


          document.getElementById("iv").srcObject = null;
          document.getElementById("iv2").srcObject = null;

          console.log("Call ended");
          this.$router.push({name: 'demo', query: {user: this.$route.query.user}});
        } else {
          console.log("No active connection to hang up");
        }

    }
  },
  mounted() {
    this.openVideo();
   this.InitSocket();
    window.addEventListener('beforeunload', handleUnload);
    function handleUnload() {

      this.socket.send(`CLOSE||${this.$route.query.user}`);
    }
    console.log(this.$route.query.touser);
    if(this.$route.query.touser){
      setTimeout(() => {
        this.cn(this.$route.query.touser);
      }, 1000)
    }






  },
  beforeUnmount()
  {
    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }
    if (this.localStream) {
      this.localStream.getTracks().forEach(track => track.stop());
      this.localStream = null;
    }
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

};
</script>

<style scoped>
/* Add your styles here */
body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 800px;  /* Adjust as needed */
  max-height: 600px; /* Adjust as needed */
  position: relative;
}

.video-container {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ccc;
  background-color: #000;
}

.overlay-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;  /* Adjust size of overlay video */
  height: 30%; /* Adjust size of overlay video */
  border: 2px solid #ccc;
}

#iv2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>